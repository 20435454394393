<template>
  <div>
    <div class="main">
      <el-form label-width="120px">
        <el-form-item label="真实姓名：">
          <el-input v-model="data.adminName"></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-model="data.adminPhone"></el-input>
        </el-form-item>
        <el-form-item label="部门：">
          <el-input v-model="data.adminBumen"></el-input>
        </el-form-item>
        <el-form-item label="微信号：">
          <el-input v-model="data.adminWexin"></el-input>
        </el-form-item>
        <el-form-item label="邮箱：">
          <el-input v-model="data.adminEmail"></el-input>
        </el-form-item>
        <!-- <el-form-item label="角色选择：">
          <el-select v-model="data.roleid" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input v-model="data.roleid" disabled></el-input>
        </el-form-item> -->
        <el-form-item label="创建时间：">
          <el-input v-model="data.createTime" disabled></el-input>
        </el-form-item>
        <el-form-item label="最后登录时间：">
          <el-input v-model="data.inLoginTime" disabled></el-input>
        </el-form-item>
        <el-form-item label="登录次数：">
          <el-input v-model="data.loginNum" disabled></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="btnClick">保存</el-button>
    </div>
  </div>
</template>

<script>
import { getUser } from "@/api/common.js";
import { getRole, addEditUsers } from "@/api/system.js";
export default {
  data() {
    return {
      data: {},
      options: []
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    }
  },
  created() {
    this.getRoles(), this.getUsers();
  },
  methods: {
    async getUsers() {
      const res = await getUser();
      if (res.code === 200) {
        this.data = res.data;
      }
    },
    async getRoles() {
      const res = await getRole();
      this.options = res.data.map(item => {
        return {
          label: item.cname,
          value: item.id
        };
      });
    },
    async btnClick() {
      Object.assign(this.data, {
        Authorization: this.Authorization
      });
      const res = await addEditUsers(this.data);
      if (res.code == 200) {
        this.$message.success("修改成功");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  background-color: white;
  height: 750px;
  padding-top: 20px;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
/deep/ .el-input__inner {
  width: 470px;
}
/deep/ .el-input.is-disabled .el-input__inner {
  background-color: white;
  color: #606266;
  cursor: default;
}
</style>
